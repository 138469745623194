html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App .content {
  flex: 1;
}

.footer {
  background-color: #052c4c;
  color: white;
  padding: 20px;
  text-align: center;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 10px 0 0 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Ensure wrapping on smaller screens */
  gap: 20px;
}

.footer-links li {}

.footer-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

.footer-links a:hover {
  text-decoration: underline;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .footer {
    padding: 15px;
  }

  .footer-links {
    gap: 15px;
  }

  .footer-links a {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 10px;
  }

  .footer-links {
    flex-direction: column;
    gap: 10px;
  }

  .footer-links a {
    font-size: 0.8rem;
  }
}
