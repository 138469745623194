body {
  font-family: Arial, sans-serif;
  background-color: #052c4c; 
  margin: 0, 5%;
  padding: 0, 5%;
  box-sizing: border-box;
  min-height: 100vh; /* Ensures the body takes the full height of the viewport */
}

.App {
  min-height: 100vh; /* Ensures the App takes the full height of the viewport */
  display: flex;
  flex-direction: column;
}

.App-header {
  margin-top: 20px;
}

.tracking-status {
  margin-top: 20px;
}

.tracking-status h2 { 
  margin-bottom: 10px;
}

.tracking-status p {
  font-size: 18px;
}
