body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  background: #f9f9f9;
}

.App {
  width: 100%;
  overflow-x: hidden;
}

.ServicesLanding {
  margin-top: -5px; 
}

/* Transition Styles */
.fade-enter {
  opacity: 0;
  transform: translateY(10px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 500ms, transform 500ms;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .navbar {
    flex-direction: column;
  }
  
  .navbar a {
    margin: 10px 0;
  }
}

@media (max-width: 992px) {
  .services-cards {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 90%;
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
  .home-header h1 {
    font-size: 2.5em;
  }

  .home-header h3 {
    font-size: 1.2em;
  }

  .discover-button {
    padding: 10px 20px;
    font-size: 0.9em;
  }

  .about-us, .services, .contact {
    margin: 30px 10px;
    padding: 15px;
  }

  .services-cards {
    flex-direction: column;
  }

  .card {
    width: 90%;
    margin: 10px 0;
  }
}

@media (max-width: 576px) {
  .home-header h1 {
    font-size: 2em;
  }

  .home-header h3 {
    font-size: 1em;
  }

  .discover-button {
    padding: 10px 15px;
    font-size: 0.8em;
  }

  .about-us, .services, .contact {
    margin: 20px 5px;
    padding: 10px;
  }

  .services-cards {
    flex-direction: column;
  }

  .card {
    width: 100%;
    margin: 5px 0;
  }
}
