.description-login {
    overflow: auto;
    color: whitesmoke;
}

.container-login {
    background-color: rgba(255, 255, 255, 0.2);
    margin-block: 20px;
    margin-inline: 30rem;
    padding: 2.5rem; 
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}

@media (max-width: 1200px) {
    .container-login{
        margin-inline: 20rem; 
    }
    
}

@media (max-width: 500px) {
    .container-login{
        margin-inline: 1rem; 
    }
    
}